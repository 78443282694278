/**
 * The function `todayFormatted` returns the current date in the format "YYYY-MM-DD".
 * @returns The function `todayFormatted` returns the current date formatted as "YYYY-MM-DD".
 */
export const todayFormatted = () => {
  const todayLocal = new Date();
  const year = todayLocal.getFullYear();
  const month = String(todayLocal.getMonth() + 1).padStart(2, "0");
  const day = String(todayLocal.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
