import { useNavigate } from "react-router-dom";
import "./login.css";
import { useEffect, useState } from "react";
import axiosInstance from "../../axiosConfig";
import rampLogo from "../../assets/images/ramp-logo.svg";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = !!localStorage.getItem("accessToken");
    if (isLoggedIn) {
      navigate("/expenses");
    }
  }, []);

  /**
   * The handleSubmit function is an asynchronous function that handles form submission for logging in a
   * user, sending a POST request to a specified endpoint with user credentials and storing the access
   * token in localStorage upon successful login.
   * @param e - The parameter `e` in the `handleSubmit` function is an event object that is passed to the
   * function when the form is submitted. It is commonly used to prevent the default form submission
   * behavior using `e.preventDefault()`. This allows you to handle the form submission manually, such as
   * making an asynchronous request
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("going to login... ");

    setError("");
    try {
      const response = await axiosInstance.post(
        "https://ramp-api.iamskgupta.com/auth/login",
        {
          userName: email,
          password,
        }
      );
      const { accessToken } = response.data.data.auth;

      // Save token in localStorage
      localStorage.setItem("accessToken", accessToken);
      navigate("/expenses");
    } catch (err) {
      setError("Invalid credentials. Please try again.");
    }
  };

  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div className="col-md-6 d-flex justify-content-md-center align-items-center bg-primary left-container">
          <div className="text-center">
            <img src={rampLogo} alt="Ramp logo" className="logo" />
          </div>
        </div>
        <div className="col-12 col-md-6 d-flex justify-content-md-center align-items-md-center pt-5">
          <div className="p-2 p-md-5 w-100 form-container">
            <h3 className="text-left mb-4">Log in to Ramp</h3>
            <form>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  required
                />
                <label htmlFor="email" className="form-label">
                  Email address
                </label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder=""
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <label htmlFor="password" className="form-label">
                  Password
                </label>
              </div>
              <div className="d-grid">
                <button
                  className="btn btn-primary btn-block float-end"
                  onClick={handleSubmit}
                >
                  Login
                </button>
              </div>
            </form>
            {error && <p style={{ color: "red" }}>{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
