import { useState, useEffect } from "react";
import axiosInstance from "../../../axiosConfig";
import PhoneAppHeader from "../header/header";
import { useNavigate } from "react-router-dom";
import "./my-expenses.css";
export default function MyExpenses() {
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const [locationFilter, setLocationFilter] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [debouncedLocationFilter, setDebouncedLocationFilter] = useState("");
  const navigate = useNavigate();

  // Debounce location filter
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedLocationFilter(locationFilter);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [locationFilter]);

  // Fetch expenses when filters change
  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const response = await axiosInstance.get("/expense", {
          params: {
            place: debouncedLocationFilter,
            from: dateFrom || undefined,
            to: dateTo || undefined,
          },
        });
        setFilteredExpenses(response.data.data);
      } catch (error) {
        console.error("Error fetching expenses:", error);
      }
    };

    fetchExpenses();
  }, [debouncedLocationFilter, dateFrom, dateTo]);

  function gotoAddExpense() {
    navigate("/add-expenses");
  }

  return (
    <>
      <PhoneAppHeader />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex mb-3">
              <div className="p-2">
                <h2>My Expenses</h2>
              </div>
              <div className="ms-auto">
                <button
                  className="btn btn-primary btn-block"
                  onClick={gotoAddExpense}
                >
                  Add
                </button>
              </div>
            </div>
          </div>

          {/* Filter Form */}
          <div className="col-12">
            <div className="filter-container">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Location"
                  value={locationFilter}
                  onChange={(e) => setLocationFilter(e.target.value)}
                />
              </div>
              <div className="d-flex">
                <div>
                  <div className="mb-3 form-floating">
                    <input
                      type="date"
                      className="form-control"
                      value={dateFrom}
                      onChange={(e) => setDateFrom(e.target.value)}
                    />
                    <label className="form-label">From</label>
                  </div>
                </div>
                <div className="ms-auto">
                  <div className="mb-3 form-floating">
                    <input
                      type="date"
                      className="form-control"
                      value={dateTo}
                      onChange={(e) => setDateTo(e.target.value)}
                    />
                    <label className="form-label">To</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Expenses List */}
          <div className="col-12 cards-container">
            {filteredExpenses.map((expense) => (
              <div key={expense.id} className="card mb-2">
                <div className="card-body">
                  <div className="d-flex">
                    <div>
                      <h4>${expense.amount}</h4>
                      {expense.category && expense.category !== "NA" ? (
                        <span className="badge rounded-pill text-bg-warning">
                          {expense.category}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="ms-auto text-end">
                      <p>
                        <b>{expense.place}</b>
                      </p>
                      <p>{expense.expenseDate}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
