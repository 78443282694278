import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../axiosConfig";
import PhoneAppHeader from "../header/header";
import { todayFormatted } from "../../../utils/date.util";
import "./add-new-expense.css";

export default function AddNewExpense() {
  const [formData, setFormData] = useState({
    place: "",
    purpose: "",
    amount: "",
    date: todayFormatted(),
  });

  const [error, setError] = useState("");
  const navigate = useNavigate();

  /**
   * The handleChange function updates the form data with the new value based on the input field name.
   * @param e - The parameter `e` in the `handleChange` function is an event object that is passed to the
   * function when an event occurs, such as a change event on an input field. This event object contains
   * information about the event that occurred, including the target element that triggered the event.
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  /**
   * The handleSubmit function handles form submission by converting the date to UTC format, making an
   * API call to add an expense, and navigating back to the expenses list upon success or displaying an
   * error message upon failure.
   * @param e - The `e` parameter in the `handleSubmit` function is an event object that represents the
   * event triggered by the form submission. In this case, it is used to prevent the default form
   * submission behavior using `e.preventDefault()`. This is commonly done in React applications to
   * handle form submissions manually and prevent the
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      // Convert date to UTC format
      const utcDate = new Date(formData.date).toISOString();

      // Make API call
      await axiosInstance.post("/expense", {
        place: formData.place,
        purpose: formData.purpose,
        amount: parseFloat(formData.amount), // Ensure amount is a number
        date: utcDate,
      });

      // Navigate back to the expenses list
      navigate("/expenses");
    } catch (err) {
      setError("Failed to add expense. Please try again.");
    }
  };

  /**
   * The handleCancel function navigates to the "/expenses" route.
   */
  const handleCancel = () => {
    navigate("/expenses");
  };

  return (
    <>
      <PhoneAppHeader />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <button
              type="button"
              className="btn btn-link back-button"
              onClick={handleCancel}
            >
              <i className="fas fa-long-arrow-alt-left"></i>
            </button>
          </div>

          <div className="col-12">
            <form
              className="add-form-container pt-4 p-3"
              onSubmit={handleSubmit}
            >
              <h3>Add new expense</h3>
              {error && <p className="text-danger">{error}</p>}
              <div className="form-floating my-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Macy, Times square"
                  name="place"
                  value={formData.place}
                  onChange={handleChange}
                  required
                />
                <label>Place</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="T-shirt"
                  name="purpose"
                  value={formData.purpose}
                  onChange={handleChange}
                  required
                />
                <label>Purpose</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="number"
                  className="form-control"
                  placeholder="23"
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                  required
                />
                <label>Amount</label>
              </div>

              <div className="mb-3 form-floating">
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  required
                />
                <label className="form-label">Expense Date</label>
              </div>

              <div className="d-flex flex-row-reverse">
                <button className="btn btn-primary" type="submit">
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-secondary me-3"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
