import "./App.css";
import MyExpenses from "./components/phone-app/my-expenses/my-expenses";
import AddNewExpense from "./components/phone-app/add-new-expense/add-new-expense";
import Login from "./components/login/login";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

function App() {
  const isLoggedIn = !!localStorage.getItem("accessToken");
  console.log("is logged in: ", isLoggedIn);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/expenses"
          element={isLoggedIn ? <MyExpenses /> : <Navigate to="/login" />}
        />
        <Route
          path="/add-expenses"
          element={isLoggedIn ? <AddNewExpense /> : <Navigate to="/login" />}
        />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
