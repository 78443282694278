import rampLogo from "../../../assets/images/ramp-logo.svg";
import "./header.css";

export default function PhoneAppHeader() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="header-logo p-2 py-4">
            <img src={rampLogo} alt="app logo" />
          </div>
        </div>
      </div>
    </div>
  );
}
